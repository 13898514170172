<template>
  <v-row class="fill-height">
    <v-col>
      <h1 class="text-center">Your progress!</h1>
      <h2 class="text-center mb-2">{{ subHeading }}</h2>
      <v-card
          class="mt-6 pt-4 pb-0 px-6"
          v-for="(chart, i) in charts"
          :key="i"
      >
        <line-chart
            :data="chart"
            height="200px"
        ></line-chart>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LineChart from "../components/dashboard/LineChart";

export default {
  name: 'Report',
  components: { LineChart },
  data: () => ({
    results: {},
    isLoading: false,
    colors: ['#0D2E41', '#00798A', '#F8AF2C', '#C27691', '#D9734E']
  }),
  async created () {
    if (this.getToken) {
      try {
        await this.fetchAllData(this.getToken)
      } catch (error) {
        this.setAlerts({
          active: true,
          message: 'Fetching data failed we\'re having issues with our servers, please try again later.',
          color: 'error'
        })
      }
    }
  },
  computed: {
    ...mapGetters(['getToken', 'getStatus', 'getData']),
    subHeading () {
      const meetsMinimumReq = this.resultCounter.some(result => result > 1)

      if (!meetsMinimumReq) {
        return 'You just need one more update.'
      } else {
        return 'Here are your previous responses.'
      }
    },
    questions () {
      if (this.getStatus) {
        if (this.getStatus.metricsToDisplay) {
          return this.getStatus.metricsToDisplay
        } else {
          return []
        }
      } else {
        return []
      }
    },
    resultCounter () {
      let counter = []

      if (this.getData) {
        for (let data in this.getData) {
          if (this.getData[data]) {
            if (this.getData[data].labels) {
              counter.push(this.getData[data].labels.length)
            }
          }
        }
      }

      return counter
    },
    charts () {
      if (this.getData) {
        let charts = []
        let i = 0

        const color = (element) => {
          if (element && this.colors.length > 0) {
            return this.colors[((element % this.colors.length) + this.colors.length) % this.colors.length]
          } else {
            return this.colors[0]
          }
        }

        for (let data in this.getData) {
          if (data) {
            let name = null
            let question = this.questions.filter(question => question.value === data)

            if (question.length > 0) {
              if (question[0].name) {
                name = question[0].name
              }
            }

            if (name) {
              charts.push({
                name: name,
                data: {
                  question: question,
                  labels: this.getData[data].labels,
                  datasets: [{
                    data: this.getData[data].datasets,
                    backgroundColor: color(i),
                    cubicInterpolationMode: 'monotone',
                    fill: true
                  }]
                }
              })

              i += 1
            }
          }
        }

        return charts
      } else {
        return []
      }
    }
  },
  methods: {
    ...mapActions(['fetchAllData', 'setAlerts']),
  },
};
</script>
